<template>
  <div class="lanzhou">
    <lanzhouHeader/>
    <div class="swiper">
      <swiper :options="swiperOption" class="swiper-container" ref="getSwiper" :slides-per-view="3"
              :space-between="50" @swiper="onSwiper"
              @slideChange="onSlideChange">
        <swiper-slide class="swiper-item" v-for="(item,index) of carouselList" :key="index">
          <a :href="item.picUrl" :title="item.adName" target="_blank" rel="nofollow"
             :style="{backgroundImage:'url('+item.picSrc+')'}">
            <!--          <img class="swiper-img" :src="item.picSrc" alt :title="item.adName"/>-->
          </a>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="to_login">
        <loginHome></loginHome>
      </div>
    </div>
    <div class="tab_wrapper">
      <ul class="tab flex">
        <li @click="demandOpens(1,'Balance')">
          <img src="@/static/lanzhou/index/icon.png" alt="" aria-hidden="true" class="inline-block middle">
          <img src="~/static/lanzhou/index/icon_w.png" alt="" aria-hidden="true" class="inline-block middle">
          <span style="display: inline-flex;transform: translateX(-15px)">按比例联网认证网报入口</span>
        </li>
        <li @click="$router.push('/blind')">
          <img src="~/static/lanzhou/index/icon1.png" alt="" aria-hidden="true" class="inline-block middle">
          <img src="~/static/lanzhou/index/icon_w1.png" alt="" aria-hidden="true" class="inline-block middle">
          <span>盲按继续教育</span>
        </li>
        <li @click="opens('com')">
          <img src="~/static/lanzhou/index/icon2.png" alt="" aria-hidden="true" class="inline-block middle">
          <img src="~/static/lanzhou/index/icon_w3.png" alt="" aria-hidden="true" class="inline-block middle">
          <span>企业需求登记</span>
        </li>
        <li @click="opens('dis')">
          <img src="~/static/lanzhou/index/icon3.png" alt="" aria-hidden="true" class="inline-block middle">
          <img src="~/static/lanzhou/index/icon_w2.png" alt="" aria-hidden="true" class="inline-block middle">
          <span>残疾人需求登记</span>
        </li>
        <li @click="goRouter('/CJRproject')">
          <img src="~/static/lanzhou/index/icon4.png" alt="" aria-hidden="true" class="inline-block middle">
          <img src="~/static/lanzhou/index/icon_w4.png" alt="" aria-hidden="true" class="inline-block middle">
          <span>残疾人培训项目</span>
        </li>
      </ul>
    </div>
    <div class="news">
      <news></news>
    </div>
    <div class="job">
      <job></job>
    </div>
    <div class="six">
      <shoppingMall/>
    </div>
    <div class="story">
      <story></story>
    </div>
    <el-dialog
        title="提出需求"
        :visible.sync="dialogVisible"
        width="590px"
        :before-close="close"
    >
      <div>
        <Needs @close="close"></Needs>
      </div>
    </el-dialog>
    <login-prompt :goShow="isHide" @close="close"></login-prompt>
    <lanzhou-footer/>
    <!--    <div class="fixed" @click="isShow = true">-->
    <!--      关于2022年兰州市按比例就业年审网申报系统更换的重要通告-->
    <!--    </div>-->
    <el-dialog
        title=""
        :visible.sync="isShow"
        width="1000px"
        :before-close="close"
    >
      <div class="dialog_content">
        <h1>关于2022年兰州市按比例就业年审网报系统更换的重要通告</h1>
        <p class="tips">
          温馨提示：按照中国残联有关规定，网上申报系统已于2022年11月30日关闭，不再开展网上审核工作。申办系统（窗口）将于2022年12月15日关闭，请还未完成年审申报工作的用人单位及时到线下窗口申办。<br/>
          地址：城关区庆阳路94号（东方红广场主席台西侧便道内办公楼一楼左边就业科）<br/>
          电话：8854207
        </p>
        <h3>一、审核对象</h3>
        <div>
          本市行政区域内的国家机关、社会团体、企事业单位、民办非企业单位等各类组织（以下简称“用人单位”）。
        </div>
        <h3>
          二、审核时间
        </h3>
        <div>
          2022年4月1日——2022年10月31日（疫情期间可顺延）
        </div>
        <h3>
          三、年审方式
        </h3>
        <div>
          用人单位可采取网上申报或窗口申报的方式参加残疾人按比例就业审核认定工作。
        </div>
        <h4>
          （一）网上申报
        </h4>
        <div>
          用人单位注册登录甘肃省政务服务网（网址 <a href="http://zwfw.gansu.gov.cn" target="_blank">http://zwfw.gansu.gov.cn</a>），搜索“按比例”进入“全国残疾人按比例就业情况联网认证”事项专区，按页面提示自行申报。（用人单位申报手册见本通告"五、附件"自行下载）。
        </div>
        <h4>
          （二）窗口申报
        </h4>
        <div>
          1．已安排残疾人就业的市直机关、团体、事业单位到兰州市残疾人劳动就业服务中心办理年审手续。（地址：兰州市城关区庆阳路94号（兰州电视台旁边大院院内一楼）,联系电话：0931-8854207）。
        </div>
        <div>
          2．县区安排残疾人的机关、团体、事业单位到同级残联或所属残疾人就业服务机构办理年审手续。
        </div>
        <div>
          3．已安排残疾人就业的企业、民办非企业单位等各类组织到生产经营所在地县（市、区）残联或就业服务机构办理年审手续。
        </div>
        <div>
          4．用人单位年审应当提交下列材料：
          <p>
            （1）《甘肃省按比例安排残疾人就业年审报表》（甘肃残疾人就业信息网下载 <a href="http://www.gscjrjy.org.cn"
                                                                                target="_blank">www.gscjrjy.org.cn</a>）；
          </p>
          <p>
            （2）从业残疾人员本人的《中华人民共和国残疾人证》或《中华人民共和国残疾军人证》复印件；
          </p>
          <p>
            （3）用人单位与从业残疾人员签订的劳动合同复印件；
          </p>
          <p>
            （4）从业残疾人员2021年全年参加社会基本保险缴费明细复印件（参保月份即就业月份，按实际计算）；
          </p>
          <p>
            （5）从业残疾人员2021年全年收入明细单据复印件，并加盖单位公章。
          </p>
        </div>
        <h3>
          四、关于年审的相关事项
        </h3>
        <div>
          1．用人单位按照此通告的地点和时间进行年审及缴纳。
        </div>
        <div>
          2．用人单位申报上年度本单位安排的残疾人就业人数（上年度未安排残疾人的用人单位无需申报），未在规定时限申报的，视为未安排残疾人就业，并根据相关部门提供的用人单位在职职工人数或经调查核实的职工人数，核算缴纳残疾人就业保障金。
        </div>
        <div>
          3．用人单位将残疾人录用为在编人员或依法与就业年龄段内的残疾人签订1年以上（含1年）劳动合同，且残疾人实际在岗，支付的工资不低于当地最低工资标准，并足额缴纳五险一金，方可计入用人单位所安排的残疾人就业人数。
        </div>
        <div>
          4．本通告自刊登之日起满30日即视为送达。
        </div>
        <h3>
          五、附件
        </h3>
        <div>
          <a href="https://imgcdpee.cdpee.org.cn/4028855882d3b1c20182d8fb5e400481.pdf" download="用人单位申报手册">用人单位申报手册.pdf</a>
          <a href="https://imgcdpee.cdpee.org.cn/4028855882d3b1c20182d8fb5e400481.pdf" target="_blank"
             download="用人单位申报手册">下载至本地</a>
        </div>
      </div>
    </el-dialog>
    <!--    <div class="weimu_images" @click.stop="isShow2 = true" v-if="!isShow2">-->
    <!--      关于2023年兰州市残疾人按比例就业情况联网认证系统的重要通知-->
    <!--    </div>-->
    <!--    <div class="weimu" v-if="isShow2" @click="close2">-->
    <!--      <div class="dialog_content">-->
    <!--        <div class="title">-->
    <!--          <h6>通知</h6> <i class="el-icon-close"></i>-->
    <!--        </div>-->
    <!--        <h1 style="line-height: 32px">2023年兰州市残疾人按比例就业情况联网认证系统于10月31号关闭</h1>-->
    <!--        <div>-->
    <!--          2023年兰州市残疾人按比例就业情况联网认证系统于10月31号关闭，请各用人单位在截止日期前登陆“甘肃政务服务网<a-->
    <!--            href="https://zwfw.gansu.gov.cn" target="_blank" style="margin: 0">https://zwfw.gansu.gov.cn</a>”搜索“按比例”自行申报，尽快办理。期间如有任何疑问请拨打-->
    <!--          <a href="javascript:void(0);" style="margin: 0">8854207</a>进行咨询。-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>
<script>
import news from '@/components/lanzhou-cdpe/news'
import job from '@/components/lanzhou-cdpe/job'
import story from '@/components/lanzhou-cdpe/figures'
import Needs from "@/components/enterprise/Needs";
import loginPrompt from "@/components/public/loginPrompt";
import loginHome from "@/components/login/loginHome";
import {
  mapMutations
} from 'vuex';
import lanzhouFooter from "@/components/shanxi-cdpe/footer";
import lanzhouHeader from "@/components/lanzhou-cdpe/header";
import shoppingMall from "components/lanzhou-cdpe/shoppingMall";

export default {
  metaInfo: {
    title: "兰州市残疾人就业创业网络服务平台-兰州市残联官方残疾人求职网站",
    meta: [
      {
        name: "keywords",
        content:
            "兰州残疾人,残疾人就业, 兰州残疾人就业,兰州残疾人找工作,兰州残疾人,残疾人招聘,残疾人求职,灵活就业,残疾人就业保障金",
      },
      {
        name: "description",
        content:
            "兰州市残疾人就业创业网络服务平台是兰州市残疾人劳动就业服务中心搭建的面向全市残疾人、用人单位和就业服务机构，提供在线求职、职业技能培训、需求登记、残疾人招聘、政策查询、职业能力测评、残保金审核、盲按继续教育、盲人按摩机构管理和统计分析等功能的互联网服务一站式平台。致力于解决兰州市残疾人就业出行难、就业信息及时获取难、残疾人就业服务精细化管理难等问题。",
      },
    ],
  },
  data() {
    return {
      isLogin: "",
      dialogVisible: false,
      isHide: false,
      carouselList: [],
      swiperOption: {
        // loop : true,
        autoplay: {
          autoplay: true,
          disableOnInteraction: false
        },
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        lazy: {
          loadPrevNext: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      isShow: false,
      isShow1: false,
      isShow2: false,
      isShow3: false,
      disProductList: [],
    }
  },
  components: {
    news,
    job,
    story,
    Needs,
    loginPrompt,
    loginHome,
    lanzhouHeader,
    lanzhouFooter,
    shoppingMall
  },
  methods: {
    ...mapMutations(['getAllCity']),
    swiper() {
      new Promise((resolve, reject) => {
        if (this.$refs.mySwiper) {
          resolve(this.$refs.getSwiper.swiper);
        }
      })
    },
    product() {
      this.$api
          .productList({
            pageNum: 1,
            pageSize: 5
          })
          .then(res => {
            res = res.data;
            if (res.success) {
              this.disProductList = res.data?.records ?? [];
            }
          });
    },
    opens(val) {
      this.width = "590px;";
      if (this.isLogin) {
        if (val == "com") {
          if (this.isLogin.userType == '2') {
            this.dialogVisible = true;
          } else {
            this.$message('您不是企业，不能登记企业需求！')
          }
        } else if (val == "dis") {
          if (this.isLogin.userType == "1") {
            this.dialogVisible = true;
          } else {
            this.$message("您不是残疾人，不能登记残疾人需求！")
          }
        }
      } else {
        this.$message("登录之后才可以登记需求！");
        this.isHide = true;
      }
    },
    close(val) {
      this.dialogVisible = false;
      this.isHide = false;
      this.isShow = false;
      this.isShow1 = false;
    },
    close2(val) {
      this.isShow2 = false;
    },
    //广告 轮播图
    ad() {
      this.$api.adApi({classId: "ff8080817f8ba536017f8ba536940000"}).then((res) => {
        this.carouselList = res.data.data;
        console.log(this.carouselList, 999)
      })
    },
    demandOpens() {
      // if (this.isLogin) {
      //   if (this.isLogin.userType == '2') {
      //     this.router.push('/enterprise/centre/lanzhou/deposit')
      //   } else {
      //     this.$message('您不是企业，不能进行残保金审核！')
      //   }
      // } else {
      //   this.$message("登录之后才可以登记需求！");
      //   this.isHide = true;
      // }
      window.open('http://zwfw.gansu.gov.cn')
    },
    onSwiper() {

    },
    onSlideChange() {

    },
    getOpen() {
      let date = new Date().getTime();
      let endDate = new Date('2023/10/30 23:50').getTime();
      if (date < endDate) {
        this.isShow2 = true;
      }
    },
    goRouter(url){
      this.$router.push(url);
    }
  },
  created() {
    this.ad();
    this.isLogin = JSON.parse(localStorage.getItem("userType")) || "";
    this.getAllCity('甘肃省');
    this.$getChildCity('甘肃省', '甘肃省');
    this.$nextTick(() => {
      this.swiper();
    })
  },
  mounted() {
    //判断是否移动端
    this.$mobile("https://lzmz.cdpee.org.cn/#/pages/index");
  },
}
</script>

<style scoped lang="less">
// a{
//   color: #000!important;
// }
.lanzhou {
  background: #fff;

  .swiper {
    position: relative;
    background: #f9f9f9;
  }

  .swiper-container {
    height: 450px;

    .swiper-item {
      overflow: hidden;

      a {
        display: block;
        width: 100%;
        height: 450px;
        background-color: white;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: auto 100%;

        img {
          display: block;
          width: auto;
          height: 100%;
          margin: 0 auto;
        }
      }
    }

    /deep/ .swiper-slide {
      transition: all .6s;
    }

    /deep/ .swiper-pagination {
      width: 1200px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;

      .swiper-pagination-bullet {
        width: 12px;
        height: 4px;
        background: rgba(255, 255, 255, 0.6);
        border-radius: 3px;
        opacity: 1;
        transition: all .6s;
      }

      .swiper-pagination-bullet-active {
        width: 36px;
        background: white;
      }
    }
  }

  .to_login {
    top: 42px;
    position: absolute;
    right: 51.5%;
    transform: translateX(200%);
    z-index: 99;
  }
  .tab_wrapper {
    background: #F9F9F9;
    padding: 18px 0;
    border: 1px solid rgba(217, 217, 217, 0.1);

    .tab {
      width: 1200px;
      margin: 0 auto;

      li {
        flex: 1;
        height: 72px;
        line-height: 72px;
        transition: 0.5s;
        overflow: hidden;
        cursor: pointer;
        margin-right: 24px;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          transform: translateX(-18%);
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover {
          background-color: #00924b;

          span {
            color: #fff;
          }

          img:nth-of-type(1) {
            transform: translate(40%, -200%);
          }

          img:nth-of-type(2) {
            display: inline-block;
            transform: translate(-55%, 0);
          }
        }

        img {
          transition: 0.8s;

          &:nth-of-type(1) {
            transform: translateX(40%);
          }

          &:nth-of-type(2) {
            transform: translate(-40%, 200%);
          }
        }

        span {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #00924C;
          line-height: 25px;
          padding-left: 6px;
        }

      }
    }
  }
}

.job {
  background: #F9F9F9;
  border: 1px solid rgba(217, 217, 217, 0.1);
}

.fixed {
  position: fixed;
  top: 20%;
  left: 30px;
  width: 150px;
  height: 174px;
  background-image: url("~static/lanzhou/icon/icon-notify.png"), linear-gradient(298deg, #FFC165 0%, #F79302 100%);
  background-size: 89px 89px, 100% 100%;
  background-position: right bottom;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 12px 15px 0 20px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  border-radius: 12px;
  line-height: 30px;
  text-shadow: 0 2px 4px rgba(5, 37, 88, 0.3);
  cursor: pointer;
  z-index: 1000;
}

.fixed2 {
  top: 30%;
  height: 154px;
}

.dialog_content {
  background: white;
  padding: 24px;
  margin-top: -20px;

  .tips {
    color: rgba(217, 0, 27, 1);
  }

  h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 30px;
  }

  h3 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
  }

  div {
    text-indent: 2em;
    font-size: 16px;
    line-height: 28px;

    a {
      margin-right: 20px;
    }
  }

  p {
  }

  a {
    color: #007dcf;
  }

  h4 {
    font-size: 18px;
    margin: 15px 0;
  }
}

.weimu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  //background: rgba(0,0,0,.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;

  .dialog_content {
    width: 650px;
    margin-top: -150px;
    border-radius: 6px;
    box-shadow: 0 10px 40px -20px #0000001a;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-indent: 0;
      margin-top: -10px;
      margin-bottom: 24px;

      h6 {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }

      .el-icon-close {
        font-size: 18px;
        color: #666666;
        cursor: pointer;
      }
    }
  }
}

.weimu_images {
  position: fixed;
  top: 50%;
  left: 5%;
  transform: translateY(-50%);
  width: 300px;
  height: 200px;
  background: rgb(232, 1, 51);
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  box-sizing: border-box;
  line-height: 26px;
  letter-spacing: 0.8px;
  cursor: pointer;
  border-radius: 6px;
  box-shadow: 0 10px 40px -20px #0000001a;
  z-index: 1000;
}
</style>
