<template>
  <section class="get_jobs">
    <div class="title">
      <h1>
        <i></i>
        职位推荐
      </h1>
    </div>
    <div class="job_content flex">
      <ul class="nav_left">
        <li :class="{ 'active': active === 0 }" @click="tabs('', 0)">
          <div class="left">全部</div>
        </li>
        <li v-for="(item, index) in tabList" :class="{ 'active': active === index+1 }" @click="tabs(item.name, index+1)" :key="index">
          <div class="left">{{ item.name }}</div>
        </li>
      </ul>
      <ul class="job_list flex flexWarp">
        <li class="box" v-for="(item, index) in jobList.slice(0, 6)" :key="index">
          <div class="top relative">
            <h1 class="line_clamp1 cursorP" @click="
              goRouter('/job/jobDetail',  item.id)
            ">
              {{ item.jobName }}
            </h1>
            <span class="money" v-if="item.jobSalary">{{ item.jobSalary }}</span>
            <span class="money" v-else>面议</span>
            <h2 v-if="item.distype.length > 0">
              适合{{
                item.distype[0].disType.slice(0, 2) +
                item.distype[0].disTypeLevel
              }}残疾人
            </h2>
            <h2 v-else>不限残疾类型</h2>
            <p>
              <span>{{ item.threeCityid }}<i></i></span>
              <span v-if="item.exp">{{ item.exp }}<i></i></span>
              <span v-if="item.edu">{{ item.edu }}<i></i></span>
            </p>
          </div>
          <div class="bottom">
            <h3 class="cursorP line_clamp1" @click="
              goRouter('/job/businessDetails', '', item.companyInfo.id)
            ">
              {{ item.companyInfo.companyName }}
            </h3>
            <p>
              <span>{{ item.companyInfo.pr + " | " + item.companyInfo.mun }}<i></i></span>
            </p>
          </div>
        </li>
        <status type='record' v-show="isShow"></status>
      </ul>

    </div>
    <div class="more-button" @click="goRouter('/job/')">
      更多职位推荐
    </div>
  </section>
</template>
<script>
import status from '@/components/public/status.vue'

export default {
  data() {
    return {
      tabList: '',
      active: 0,
      jobList: '',
      isShow: false,
    }
  },
  components: {
    status,
  },
  methods: {
    // goRouter(id) {
    //   const { href } = this.$router.resolve({
    //     path: '/news/iddetails/',
    //     query: {
    //       id: id,
    //     },
    //   })
    //   window.open(href, '_blank')
    // },
    // 热门职位分类
    async v2hotSearchWord() {
      let res = await this.$api.v2hotSearchWord({ pageSize: 6 })
      this.tabList = res.data
    },
    tabs(name, val) {
      this.active = val
      this.getJob(name)
    },
    // 推荐职位
    async getJob(name) {
      let res = await this.$api.getHomeJobsApi({
        jobOrCompanyName: name,
        location: '',
        pageNum: 1,
        pageSize: 9,
      })
      if (res.data.success) {
        this.jobList = res.data.data.records
        if (this.jobList.length <= 0) {
          this.isShow = true
        } else {
          this.isShow = false
        }
      }
    },
    goRouter(url, id, companyId) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
          companyId: companyId,
        },
      })
      window.open(href, '_blank')
    },
  },
  created() {
    this.v2hotSearchWord()
    this.getJob()
  },
}
</script>
<style lang="less">
.get_jobs {
  width: 1200px;
  margin: 0 auto;
  .more-button {
    width: 100%;
    height: 54px;
    background: #ffffff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 22px;
    cursor: pointer;
    &:hover {
      color: #00924c;
    }
     margin: 24px 0 36px;
  }
  .title {
    margin-top: 36px;
    margin-bottom: 22px;

    h1 {
      position: relative;
      font-size: 28px;
      font-weight: bold;
      padding-left: 16px;
      cursor: pointer;
      font-family: PingFangSC-Semibold, PingFang SC;

      i {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 30px;
        background: linear-gradient(
          180deg,
          rgba(0, 146, 76, 0.8) 0%,
          #ffffff 100%
        );
      }

      &:hover {
        color: #00924b;
      }
    }
  }

  .job_content {
    background: white;
    .nav_left {
      width: 180px;
      margin-right: 18px;
      li {
        display: flex;
        height: 66px;
        cursor: pointer;
        align-items: center;
        margin-bottom: 2px;
        background: rgba(217, 217, 217, 0.1);
        border-radius: 2px 0px 0px 2px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        &:last-child {
          margin-bottom: 0;
        }
        &:hover {
          color: #00924b;
        }
        &.active {
          position: relative;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
          background: white;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #00924c;
          }
        }

        .left {
          padding: 0 28px;
        }
      }
    }

    .job_list {
      flex: 8;
      flex-wrap: wrap;
      padding-top: 18px;
    }

    .box {
      width: 310px;
      height: 178px;
      border: 1px solid #ededed;
      margin-right: 10px;
      margin-bottom: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      transition: 0.5s;
      padding: 17px 15px;
      &:hover {
        box-shadow: 0 0 15px #eeeeee;
      }

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      .top {
        padding-bottom: 15px;
        border-bottom: 1px dashed #ededed;
        h1 {
          width: 210px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          margin-bottom: 5px;
          &:hover {
            color: #00924b;
          }
        }

        h2 {
          color: #666666;
          margin-bottom: 10px;
          font-size: 14px;
        }

        p {
          span {
            position: relative;
            padding-right: 20px;
            color: #999999;

            &:last-child {
              i {
                display: none;
              }
            }

            i {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
              width: 1px;
              height: 10px;
              border-right: 1px solid #999999;
            }
          }
        }

        .money {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 18px;
          font-weight: bold;
          color: #fc6c38;
        }
      }

      .bottom {
        padding: 10px 0;

        h3 {
          margin-bottom: 5px;
        }

        &:hover {
          color: #00924b;
        }

        p {
          font-size: 12px;

          span {
            position: relative;
            padding-right: 10px;
            color: #999999;

            &:last-child {
              i {
                display: none;
              }
            }

            i {
              position: absolute;
              top: 50%;
              right: 0;
              transform: translateY(-50%);
              width: 1px;
              height: 10px;
              border-right: 1px solid #999999;
            }
          }
        }
      }
    }
  }
}
</style>
