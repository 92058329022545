<template>
  <div class="login">
    <div class="form_content" v-if="login == ''">
      <ul class="tab">
        <li @click="changeTab(0)" :class="active === 0?'active':''">个人登录</li>
        <li @click="changeTab(1)" :class="active === 1?'active':''">企业登录</li>
      </ul>
      <el-form :model="form" :rules="error" ref="form" class="form" @submit.native.prevent>
        <el-form-item prop="userName">
          <el-input v-model.trim="form.userName" class="wrap_input" placeholder="请输入手机号码"
                    @change="getFindIphone(form.userName)"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
              class="wrap_input"
              v-model="form.password"
              placeholder="请输入短信验证码"
              onkeyup="this.value=this.value.replace(/^ +| +$/g,'')"
          >
            <template slot="append">
              <el-button
                  @click="sendCheckCode"
                  class="sendcode"
                  :class="{ 'disabled-style': getCodeBtnDisable }"
                  :disabled="getCodeBtnDisable"
              >{{ btntxt }}
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="buttons" style="display:flex;">
            <el-button slot="reference" native-type="submit" :type="form.userName?'primary':'info'" class="btn"
                       :loading="loading"
                       @click="register"
                       @keyup.enter="register"
                       style="color:white;flex: 1;">
              登录
            </el-button>
            <el-button class="w230" style="flex: 1;margin-left: 10px" type="primary" v-loading="loading" @click="goRouter('/login?active=0')">注册
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="checkbox" style="margin-bottom: 0;">
          <el-checkbox v-model="form.checkbox" class="checked">
            我接受
            <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
            和
            <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
          </el-checkbox>
        </el-form-item>
        <!-- <div class="tip"><span>未注册手机号验证后将自动注册本平台账号</span></div> -->

        <!-- <el-form-item prop="checkbox">
            我接受
            <router-link to="/protocol/userServe/" target="_blank">用户服务协议</router-link>
            和
            <router-link to="/protocol/agreement/" target="_blank">隐私政策</router-link>
          </el-checkbox>
        </el-form-item> -->
      </el-form>
      <div class="more cursorP" @click="goRouter(active == 0?'/Login':'/user/companyLogin')">
        其他登录方式
      </div>
    </div>
    <div class="form_content" v-if="login.userType == 1">
      <div class="login_passerby login_user">
        <img :src="login.logo || require('@/static/login/avatar.png')" alt="" class="avatar" style="width: 66px;height: 66px;border-radius: 50%">
        <h3>{{ login.userName }}</h3>
        <p>欢迎回来，{{ getHour() }}</p>
        <ul class="authentication">
          <li>
            <router-link to="/center/work/?active=1">
              <h4>{{ countList.LOOKS || 0 }}</h4>
            </router-link>
            <p>家企业看过</p>
          </li>
          <li>
            <router-link to="/center/work/?active=2">
              <h4>{{ countList.FEEDS || 0 }}</h4>
            </router-link>
            <p>家企业反馈</p>
          </li>
        </ul>
        <ul class="list">
          <li>
            <router-link :to="'/center/resume?id='+login.id+'&userType=1'" target="_blank">
              <img src="~/static/login/resumes.png" alt="">
              <p>我的简历</p>
            </router-link>
          </li>
          <li @click="resetResume">
            <img src="~/static/login/reset.png" alt="">
            <p>刷新简历</p>
          </li>
          <li>
            <a href="javascript:void(0);" @click="isShow = true">
              <img src="~/static/login/qr_xiao.png" alt="">
              <p>下载APP</p>
            </a>

          </li>
        </ul>
      </div>
    </div>
    <div class="form_content" v-if="login.userType == 4">
      <div class="login_passerby">
        <img src="~/static/login/avatar.png" alt="" class="avatar">
        <h3>{{ login.userName }}</h3>
        <p>欢迎回来，{{ getHour() }}</p>
        <div class="authentication" v-if="login.touristType == 1">
          多家企业都对您感兴趣，抓紧完善简历开始投递吧
          <router-link to="/userLogin/authentication" target="_blank"> 点击完善~</router-link>
        </div>
        <div class="authentication" v-else>
          很遗憾，您的企业信息尚未完善，无法操作发布职位，如果享受更多服务，请点击完善企业资料
          <router-link to="/companyLogin/authentication" target="_blank"> 点击完善~</router-link>
        </div>
        <ul class="list">
          <li @click="goRouter('/asklib/')">
            <h4>
              9
            </h4>
            <p>条圈子问答</p>
          </li>
          <li>
            <a href="https://www.cdpee.org.cn/download/index.html" target="_blank">
              <img src="~/static/login/qr_xiao.png" alt="">
              <p>下载APP</p>
            </a>

          </li>
        </ul>
      </div>
    </div>
    <div class="form_content shanxi_company" v-if="login.userType == 2">
      <div class="login_passerby">
        <img src="~/static/login/avatar.png" alt="" class="avatar">
        <h3>{{ login.userName }}</h3>
        <div class="btn">
          <el-button type="primary" @click="$router.push('/enterprise/publish/')">发布职位</el-button>
          <el-button type="info" plain @click="$router.push('/enterprise/post/')">管理职位</el-button>
        </div>
        <div class="resumes">
          <ul>
            <li @click="goRouter('/enterprise/resumes?ids=1')">
              <span class="num">{{ count.noApplys }}</span>
              <span class="text">待处理简历</span>
            </li>
            <li @click="goRouter('/enterprise/resumes?ids=2')">
              <span class="num">{{ count.looks }}</span>
              <span class="text">已查看简历</span>
            </li>
            <li @click="goRouter('/enterprise/resumes?ids=3')">
              <span class="num">{{ count.intention }}</span>
              <span class="text">有意向简历</span>
            </li>
            <li @click="goRouter('/enterprise/resumes?ids=36')">
              <span class="num">{{ count.interviews }}</span>
              <span class="text">面试邀请</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog
        custom-class="el-dialog_tip"
        :visible.sync="isHide"
        :modal-append-to-body="true"
        :append-to-body="true"
        width="300px"
        :show-close="false"
        :before-close="handleClose">
      <div class="alert">
        <img src="~/static/login/tip.png" alt="">
        <h3>您当前短信登录的是求职者账号 <br/>
          如想招聘员工请进入企业版
        </h3>
        <div class="btn flex">
          <el-button type="primary" plain @click="goRouter('/user/companyLogin')">前往企业版</el-button>
          <el-button type="primary" @click="isSend">继续</el-button>
        </div>
        <el-checkbox v-model="dialogCheckbox" class="checked" @change="handChange">
          我已了解，不再提示
        </el-checkbox>
      </div>
    </el-dialog>
    <el-dialog
        custom-class="el-dialog_tip"
        :visible.sync="isShow"
        :modal-append-to-body="true"
        :append-to-body="true"
        width="350px"
        :before-close="handleClose">
      <div class="alert">
        <img src="@/static/public/apps.png" alt="APP下载二维码"/>
      </div>
    </el-dialog>
    <dialogImageCode ref="dialogImageCode" @changeCode="sendMsg"/>
  </div>
</template>

<script>
import dialogImageCode from "components/login/dialogImageCode";
export default {
  name: "loginHome",
  inject: ["reload"],
  props: ["actives"],
  components:{dialogImageCode},
  data() {
    return {
      active: 0,
      btntxt: "获取验证码",
      time: 180,
      getCodeBtnDisable: false,
      loading: false,
      isHide: false,
      dialogCheckbox: false,
      isShow:false,
      form: {
        userName: '',
        password: '',
        checkbox: false,
        loginType: "1",
        touristType: "1",
      },
      error: {
        userName: [
          {
            required: true,
            message: "请输入您的手机号码",
            trigger: "blur",
          },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        password: [
          {required: true, message: "请输入收到的验证码", trigger: "blur"},
        ],
        checkbox: [
          {required: true, message: "请先阅读并勾选用户服务协议", trigger: "change"},
        ],
      },
      login: '',
      location: '',
      count: '',
      getIsLogin: '',
      countList: '',
    }
  },
  computed: {
    loginType() {
      return this.$store.state.login.loginType
    }
  },
  watch: {
    loginType(val) {
      this.active = val == 2 ? 1 : 0;
    }
  },
  methods: {
    changeTab(index) {
      this.active = index;
      this.$store.commit('SET_LOGIN_TYPE', index == 1 ? 2 : 1)
    },
    //手机号登录注册
    register() {
      let that = this;
      if (!this.form.checkbox) {
        this.$message.error("请先阅读并勾选用户服务协议和隐私政策");
        return;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          let res = await this.$api.getLoginApi(this.form);
          if (res.data.success) {
            this.loading = false;
            let user = res.data.data;
            that.$message.success('登录成功！');
            //残疾人个人信息
            that.$store.commit("SET_USER_INFO", user);
            //存储access_token
            that.$store.commit("SET_TOKEN", user.token);
            //存储refresh_token
            that.$store.commit("SET_REFRESH_TOKEN", user.token);
            this.reload();
            let red = await this.$api.wangTiTrainApplyTips();
            if (red.data.success == false) {
              this.$message.error(red.data.msg)
            }
            if (this.$route.query.sourceId && this.getIsLogin == '200') {
              await this.getSourceId(this.$route.query.sourceId);
            }
            this.getDisabledCountInfo();
            if (user.userType === "1") {
              this.$router.push("/");
            } else if (user.userType === "2") {
              if (user.memberStatus == "0") {
                that.$router.push("/enterprise/centre/infomanage/");
              }
              this.$router.push("/enterprise");
              this.loading = false;
            } else {
              if (this.active == 1) {
                this.$router.push("/companyLogin/authentication");
              } else {
                this.$router.push("/userLogin/authentication");
              }
            }
          } else {
            this.loading = false;
            that.$message.error(res.data.msg);
          }
        } else {
          this.$message.error("信息填写不完整");
        }
      })
    },
    sendCheckCode() {
      let iphone = /^1[3456789]\d{9}$/;
      let codeAlert = localStorage.getItem('codeAlert');
      if (iphone.test(this.form.userName)) {
        if (this.active == 0) {
          if (codeAlert) {
            this.$refs.dialogImageCode.open();
          } else {
            this.isHide = true;
            return
          }
        } else if (this.active == 1) {
          this.$refs.dialogImageCode.open();
        }
      } else {
        this.$message.error("手机号码不正确");
      }
    },
    isSend(){
      this.$refs.dialogImageCode.open();
      this.isHide = false;
    },
    // 单独领出来的短信发送接口
    sendMsg(val) {
      this.getCodeBtnDisable = true;
      this.btntxt = '发送中...';
      this.$axios
          .post(
              "/api/app-jycy-phoneverificationcode/verification",
              {
                phone:this.form.userName,
                uuid:val.uuid,
                code:val.code
              }
          ).then(res => {
        if (res.data.success) {
          this.$message.success({
            message: "验证码已经发送至您的手机，请注意接收",
          });
          this.time = 180;
          this.timer();
        } else {
          this.getCodeBtnDisable = false;
          this.btntxt = '获取验证码';
          this.$message.error({
            message: res.data.msg || "发送失败",
          });
        }
      })
      this.handleClose();
      this.$refs.dialogImageCode.close();
    },
    //倒计时
    timer() {
      if (this.time > 0) {
        this.time--;
        this.btntxt = this.time + "s后重新获取";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = "获取验证码";
        this.getCodeBtnDisable = false;
      }
    },
    goRouter(url, id) {
      this.$router.push({path: url, query: {id: id}})
      // window.location.href = url;
    },
    handChange(val) {
      if (val) {
        localStorage.setItem('codeAlert', val);
      } else {
        localStorage.removeItem('codeAlert');
      }
    },
    handleClose() {
      this.isHide = false;
      this.isShow = false;
    },
    //刷新简历
    async resetResume() {
      let res = await this.$api.refreshResume();
      if (res.data.success) {
        this.$message.success("简历刷新成功");
      }
    },
    // 企业登录状态收到简历数量
    getReceiveCount() {
      this.$api.getReceiveCount().then((res) => {
        this.count = res.data.data;
      })
    },
    getHour() {
      let date = new Date();
      let hour = date.getHours();
      let time;
      if (hour < 9) {
        time = '早上好';
      } else if (hour < 12) {
        time = '上午好';
      } else if (hour < 17) {
        time = '下午好';
      } else {
        time = '晚上好';
      }
      return time;
    },
    //验证手机号码是否注册
    getFindIphone(username) {
      this.$api.getfindIphoneApi({iphone: this.$sm2Encrypt(username)}).then(res => {
        if (res.data.code == 8112) {
          this.form.checkbox = true;
        } else if (res.data.code == 200) {
          this.getIsLogin = res.data.code;
        }
      })
    },
    // 判断注册用户来源
    async getSourceId(sourceId) {
      await this.$api.addSourceByNewUserApi({sourceId: sourceId});
    },
    // 数量统计
    getDisabledCountInfo() {
      this.$api
          .getDisabledCountInfo()
          .then((res) => {
            console.log('res',res.data)
            if (res.data.success) {
              this.countList = res.data.data
              this.loading = false
            } else {
              console.log('错误了', error)
              this.loading = false
            }
          })
          .catch((error) => {
            console.log('错误了', error)
          })
    },
  },
  created() {
    this.login = this.$store.state.userType || JSON.parse(localStorage.getItem("userType")) || '';
    this.location = localStorage.getItem("locationSite") || '';
    if (this.login) {
      this.getReceiveCount();
      this.getDisabledCountInfo();
    }
  }
}
</script>

<style scoped lang="less">
.login {
  width: 312px;
  background: white;
  border-radius: 6px;
  box-shadow: 0px 0px 36px 0px rgba(0, 18, 10, 0.1);

  .form_content {
    padding: 18px;

    .tab {
      display: flex;

      li {
        flex: 1;
        padding-bottom: 10px;
        text-align: center;
        font-size: 16px;
        color: #66716C;
        margin-right: 24px;
        cursor: pointer;

        line-height: 28px;
      }

      li.active {
        position: relative;
        font-size: 18px;
        font-weight: bold;
        color: #00120A;
      }

      li.active::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        width: 42px;
        height: 4px;
        border-radius: 2px;
        background: #28A46D;
      }
    }

    /deep/ .form {
      padding: 22px 0;

      .el-form-item__content {
        height: 42px;
        line-height: 42px;
      }

      .el-form-item {
        margin-bottom: 24px;

        .el-input__inner::placeholder {
          font-size: 14px;
          color: #99A09D;
        }

        .el-input-group--append {
          border: 1px solid #CECFD0;
          border-radius: 6px;

          .el-input__inner {
            border: none;
          }
        }

        .el-input-group__append {
          border: none;
          padding: 0 10px;
          background: rgba(0, 0, 0, 0.03);

          span {
            display: inline-block;
            font-size: 14px;
            color: #66716C;
            padding-left: 12px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
          }

          .el-button.is-disabled, .el-button.is-disabled:hover, .el-button.is-disabled:focus {
            border: none;
            background: none;
            color: #28A46D;
          }
        }

        .btn {
          width: 100%;
          font-size: 14px;
          border: none;
        }

        .el-button--info {
          color: #99A09D;
          background: rgba(0, 0, 0, 0.05);
        }

        .checked {
          color: #66716C;
          margin-left: 20px;

          a {
            color: #28A46D;
          }
        }

        .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #66716C;
        }
      }

      .button {
        margin-bottom: 0;
      }
    }

    .tip {
      text-align: center;
      font-size: 12px;
      color: #ff9100;
    }

    .login_passerby {
      padding: 22px 30px;
      text-align: center;

      .avatar {
        margin-bottom: 12px;
      }

      h4 {
        font-size: 18px;
      }

      p {
        color: #66716C;
      }

      .authentication {
        padding-top: 38px;
        text-align: left;
        color: #66716C;

        a {
          color: #28A46D;
          text-decoration: underline;
        }
      }

      .list {
        padding-top: 36px;
        display: flex;
        align-items: center;

        li {
          flex: 1;
          cursor: pointer;

          h4 {
            font-size: 34px;
            color: #28A46D;
            margin-top: 10px;
            margin-bottom: 15px;
          }

          p {
            font-size: 16px;
            color: #66716C;
          }
        }

        /*li:nth-of-type(1){*/
        /*    p{*/
        /*        transform: translateY(1px);*/
        /*    }*/
        /*}*/
      }
    }

    .login_user {
      padding: 2px 5px;

      .authentication {
        display: flex;

        li {
          flex: 1;
          text-align: center;

          h4 {
            font-size: 34px;
            color: #28A46D;
            margin-bottom: 10px;
          }
        }

        li:first-child {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1px;
            height: 48px;
            background: rgba(0, 0, 0, .1);
          }
        }
      }
    }

    .more {
      width: 96px;
      height: 30px;
      margin: 1px auto 2px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #66716C;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      font-size: 12px;
      // transform: translateY(-10px);
    }

    .more:hover {
      color: white;
      background: #28A46D;
    }
  }
}

.button {

  background-color: #28A46D;
  border-radius: 6px;


}

.style_padding {
  padding: 0 !important;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background: #28A46D;
  border-color: #28A46D;
}

//登录状态弹窗提示
/deep/ .el-dialog_tip {
  color: red;

  .el-dialog__body {
    padding-top: 0;
  }

  .alert {
    text-align: center;
    border-radius: 6px;

    img {
      margin-bottom: 12px;
    }

    h3 {
      font-size: 16px;
    }

    .btn {
      display: flex;
      justify-content: center;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 18px;

      .el-button {
        width: 102px;
        text-align: center;
      }
    }
  }
}

.shanxi_company {
  .btn {
    padding: 20px 0 10px;

    .el-button--info.is-plain {
      color: #28A46D;
    }
  }

  .resumes {
    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        cursor: pointer;
        width: 100px;
        display: flex;
        flex-direction: column;
        padding: 11px 0;

        .num {
          font-size: 28px;
          font-weight: 700;
          color: #28A46D;
        }

        .text {
          margin: 5px;
        }
      }
    }
  }
}
</style>
